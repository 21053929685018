import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ObjectUnsubscribedError, Observable, OperatorFunction, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';



@Component({
	selector: 'app-typeahead-basic',
	standalone: true,
	imports: [NgbTypeaheadModule, FormsModule, JsonPipe],
	templateUrl: './typeahead-basic.component.html',
	styleUrls: ['./typeahead-basic.component.scss'	]
})
export class TypeaheadBasicComponent {
  @Input() list: any;
  @Output() selectedItemEmitter = new EventEmitter<any>();
  @Input() events: Observable<void>;
  @Input() mode: string;
  private eventsSubscription: Subscription;
  model: any;

  ngOnInit(){
    this.eventsSubscription = this.events.subscribe(() => this.model = '');
  }

  constructor() {  }

  selectedItem = (event: any) => {
    console.log('--event---', event);
    this.selectedItemEmitter.emit(event.item);
  }

  formatter = (x: any) => x.displayName;
  
	search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) => {
    let objs = text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map((term) => term.length < 2 ? [] : this.list?.filter((obj: any) => obj.displayName?.toLowerCase()?.includes(term.toLowerCase())).slice(0, 5)
			)
		);
    return objs;
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
		