import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../http.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  resetEvent: Subject<string> = new Subject<string>();
  data: any = { staff: [] };
  enrichedData: any = { staff: [] };
  directorate: any = {};
  filteredData: any;
  suggestedMatches: any = [];
  isLoading: boolean = true;
  EmpMap: any = {};
  root: any = null;
  currentDir = "";
  currentEmp = null;
  constructor(private httpService: HttpService) { }
  ngOnInit(): void {
    this.httpService.get().subscribe(data => {
      this.data = data;
      this.enrichedData.staff = this.data.staff.map((obj: any) => {
        const tmpObj = this.buildObj(obj, false, false);
        if (tmpObj.id === 4427) {
          tmpObj.directorate.push('Equity, Diversity, and Inclusion');
        }
        return tmpObj;
      });
      this.suggestedMatches = this.enrichedData.staff;
      this.enrichedData.staff.map((obj: any) => {
        obj.directorate.forEach((element: any) => {
          this.directorate[element] = true;
        });
        this.EmpMap[obj.id] = { id: obj.id, parent: obj.parentId };
        return obj;
      }).map((obj: any) => {
        obj.level = this.getNodeLevel(obj.id);
        if (obj.level === 0) {
          this.root = obj;
        }
      });
      this.loadInitial();
      
      this.isLoading = false;
    });
  }

  getDirList() {
    return Object.keys(this.directorate).sort();
  }

  updateFilters = (event: any) => {   
    const list = this.enrichedData.staff.filter((i: any) => {
      const passDir = !event.directorate || i?.directorate.includes(event.directorate);
      const passSearch = !event.search || i?.displayName.toLowerCase()?.includes(event.search.toLowerCase());
      const decision = !(!event.directorate && !event.search) && passDir && passSearch;
      return decision;
    }).map((obj: any) => {
      return this.copyObj(obj, false, false);
    });

    this.suggestedMatches = list.slice(0, 5);
  }

  loadInitial = () => {    
    this.filteredData = this.enrichedData.staff.filter((i: any) => i.id == this.root.id || i.parentId == this.root.id).map((obj: any) => {
      if (obj.id === this.root.id) {
        this.setCurrentValues(obj);
      }      
      return this.copyObj(obj, obj.id === this.root.id, obj.id === this.root.id);
    });
  }

  setCurrentValues = (obj: any) => {
    this.currentDir = obj?.directorate;
    this.currentEmp = obj.id;
  }

  setCurrentValuesWithDir = (obj: any, dir: string) => {   
    this.currentDir = obj?.directorate.filter((i:any) => i === dir)[0];
    this.currentEmp = obj.id;
  }

  copyObj = (obj: any, isRoot: boolean, isSelected: boolean) => {
    return {...obj, parentId: obj.parentId === 0 || isRoot || isSelected ? '' : obj.parentId, isSelected};
  }

  buildObj = (obj: any, isRoot: boolean, isSelected: boolean) => {
    const numOfChildren = this.data.staff.filter((i: any) => i.SupervisorID === obj.EmployeeID).length;
    const object = {
      displayName: obj.DiplayName,
      email: obj.Email,
      phone: obj.UpdatedPhone,
      positionTitle: obj.PositionTitle,
      id: obj.EmployeeID,
      directorate: [obj.Directorate],
      parentId: obj.SupervisorID === 0 || isRoot || isSelected ? 0 : obj.SupervisorID,
      parentIdRef: obj.SupervisorID,
      isSelected,
      numOfChildren,
      level: obj.level
    }
    return object;
  };

  getNodeLevel = (id: number): number => {
    if (!this.EmpMap[id]?.parent) {
      return 0;
    } else {
      return 1 + this.getNodeLevel(this.EmpMap[id].parent);
    }
  }

  updateGraph = (obj: { action: string, value: any }) => {
    switch (obj.action) {
      case 'updateBySearchFromGraph': this.updateGraphBySearch(obj.value, true); break;
      case 'updateBySearch': this.updateGraphBySearch(obj.value, false); break;
      case 'updateByDir': this.updateGraphByDir(obj.value); break;
      default: break;
    }
  };

  updateGraphBySearch = (id: number, reset: boolean) => {
    const target = this.enrichedData.staff.filter((i: any) => i.id == id).map((obj: any) => {
      return this.copyObj(obj, false, false);
    })[0];

    if (target.directorate.includes(this.currentDir) && reset) {
      this.resetEvent.next('directorate');
    }
    if (this.currentEmp !== target.id && reset) {
      this.resetEvent.next('search');
    }
    this.setCurrentValues(target);
    this.filteredData = this.enrichedData.staff.filter((i: any) => i.id == target.id || i.parentId == target.id).map((obj: any) => {
      return this.copyObj(obj, obj.id == target.id, obj.id == id);
    });
  }
  
  updateGraphByDir = (dir: string) => { 
    console.log(dir);
      
    if (!dir) {
      this.loadInitial();
      return;
    }
    const allTargets = this.enrichedData.staff.filter((i: any) => i.directorate.includes(dir)).map((obj: any) => {
      return this.copyObj(obj, false, false);
    }).sort((a: any, b: any) => {
      if (a.level === b.level) {
        return b.numOfChildren - a.numOfChildren;
      } else {
        return a.level - b.level;
      }
    });

    const target = allTargets[0];
    this.setCurrentValuesWithDir(target, dir);
    this.filteredData = this.enrichedData.staff.filter((i: any) => i.id == target.id || i.parentId == target.id).map((obj: any) => {
      return this.copyObj(obj, obj.id == target.parentId, obj.id === target.id);
    });
  }
}
