import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

const DEFAULT_DURATION = 300;
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class FiltersComponent {

  @Input() directorates: any;
  @Input() suggestedMatches: any;
  @Output() updateGraphEmitter = new EventEmitter<any>();
  @Input() events: Observable<string>;
  private eventsSubscription: Subscription;
  resetSearchEvent: Subject<void> = new Subject<void>();
  resetDirEvent: Subject<void> = new Subject<void>();
  selectedDir: string;
  collapsed: boolean = true;
  constructor() { }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe((value) => {

      switch (value) {
        case 'search': this.resetSearchEvent.next(); break;
        case 'directorate': this.resetDirEvent.next(); break;
        default: return;
      }
    });
  }

  updateDir = (event: any) => {
    this.updateGraphEmitter.emit({ action: 'updateByDir', value: event });
    !!event && this.resetSearchEvent.next();
  }

  update = (event: any) => {
    this.updateGraphEmitter.emit({ action: 'updateBySearch', value: event.id });
    !!event && this.resetDirEvent.next();
  }

  toggle = () => {
    this.collapsed = !this.collapsed;
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
