<header id="header" role="banner" class="header">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="top-hat d-flex" data-ai-region="" data-ai-area="tophat">


                    <div class="top-hat-aligner d-flex align-items-center justify-content-between ">
                        <div class="apa-sites d-none d-lg-flex">
                            <nav class="sites-nav">
                                <ul class="apa-sites-list d-md-flex align-items-center">
                                    <li>
                                        <a href="https://www.apa.org">APA.org</a>
                                    </li>
                                    <li>
                                        <a href="https://apastyle.apa.org">APA Style</a>
                                    </li>
                                    <li>
                                        <a href="https://www.apaservices.org">APA Services</a>
                                    </li>
                                    <li>
                                        <a href="https://www.apadivisions.org">Divisions</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="left-nav d-flex align-items-center apa-links-right">
                            <nav class="utility-nav">
                                <ul class="d-flex align-items-center">
                                    <li class="d-none d-lg-flex">
                                        <a href="https://www.apa.org/about">About APA</a>
                                    </li>
                                    <li class="d-none d-lg-flex">
                                        <a href="https://www.apa.org/events">Events</a>
                                    </li>
                                    <li class="d-none d-lg-flex">
                                        <a href="https://www.apa.org/support" target="_parent">Help</a>
                                    </li>
                                    <li id="HeaderMyApaLink"><a href="https://my.apa.org/portal/home" rel="nofollow"
                                            target="_parent">MyAPA</a></li>
                                    <li id="HeaderLogoutLink">
                                        (<a href="https://sso.apa.org/apasso/idm/logout?ERIGHTS_TARGET=http://www.apa.org"
                                            rel="nofollow" target="_parent">Log Out</a>)
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   
    </div>


    <section class="banner">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="headLogo mt-2" id="headLogo">
                    <a href="/">
                        <img class="large" src="/assets/images/logo.png" alt="American Psychological Association Logo">
                    </a>
                </div>
            </div>
        </div>
           
          
        </div>
    </section>
</header>