import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-acc-basic',
  templateUrl: './acc-basic.component.html',
  standalone: true,
	imports: [CommonModule, NgbAccordionModule],
  styleUrls: ['./acc-basic.component.scss']
})
export class AccBasicComponent {
  @Input() directorates: any;
  @Output() selectedDirEmitter = new EventEmitter<any>();
  @Input() events: Observable<void>;
  private eventsSubscription: Subscription;
  selectedItem: any;

  ngOnInit(){
    this.eventsSubscription = this.events.subscribe(() => this.selectedItem = null);
  }

  constructor() {  }
  select = (item: any) => {
    // console.log(item);
    this.selectedItem = item;
    this.selectedDirEmitter.emit(item);
  }

  cancelSelection = () => {
    this.selectedItem = null;
    this.selectedDirEmitter.emit(null);
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
