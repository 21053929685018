<!-- <div *ngIf="!isLoading; else loading"> -->
<div>
    <app-header></app-header>
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="app-name py-3">APA Visual Directory</h1>
            </div>
        </div>
        
        <div class="row wrapper" *ngIf="!isLoading; else loading">

                <div class="col-12 p-0 col-lg-4">
                    <div class="filter-panel sticky-top h-auto">
                        <app-filters [directorates]="getDirList()" (updateGraphEmitter)="updateGraph($event)" [suggestedMatches]="suggestedMatches" [events]="resetEvent.asObservable()"></app-filters>
                    </div>
                </div>
                <div class="col-12 col-lg-8" style="z-index: 10000">
                    <div class="main">
                        <app-chart [data]="filteredData" (updateGraphEmitter)="updateGraph($event)"></app-chart>
                    </div>
                </div>
           
        </div>
    </div>
    <app-footer></app-footer>
</div>
<ng-template #loading>
    <app-loader></app-loader>
</ng-template>