<ngb-accordion #acc="ngbAccordion">
	<ngb-panel title="Departments / Directorate" >
		<ng-template ngbPanelContent>
            <ul *ngIf="directorates && !selectedItem">
                <li class="py-2" *ngFor="let item of directorates; index as i" (click)="select(item)" (keyDown.enter)="select(item)" (keyDown.space)="select(item)"><a [attr.aria-label]="item">{{item}}</a></li>
            </ul>
            <ul *ngIf="!!selectedItem" class="m-0">
                <li class="py-2 selectedItem"> <i class="ph-x-circle" aria-hidden="true" (click)="cancelSelection()" (keyDown.enter)="cancelSelection()" (keyDown.space)="cancelSelection()"></i> <a>{{selectedItem}}</a></li>
            </ul>
		</ng-template>
	</ngb-panel>
</ngb-accordion>
