import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent  implements OnInit, OnChanges {
  @ViewChild('chartContainer') chartContainer: ElementRef;
  @Output() updateGraphEmitter = new EventEmitter<any>();
  @Input() data: any[];
  chart: any;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.chart) {
      this.chart = new OrgChart();
    }
    this.updateChart();
  }

  ngOnChanges() {
    this.updateChart();
  }
  updateChart() {
    if (!this.data) {
      return;
    }
    if (!this.chart) {
      return;
    }
    this.chart
      .container(this.chartContainer.nativeElement)
      .svgWidth(window.innerWidth - 300)
      .data(this.data)
      .svgHeight(window.innerWidth < 768 ? window.innerHeight - 300: window.innerHeight - 180)
      .nodeContent((d: any) => {
        const color = '#fff';
        const color2 = d.data.isSelected ? '#003A70' : '#cccccc';
        const selected = d.data.isSelected ? 'selected' : '';
        const navUp = !!d.data.parentIdRef && d.data.isSelected ? `<div class="nav-up" role="link"  aria-label="Move up one level" att-id="${d.data.parentIdRef}"><a><i class="ph-caret-up" aria-hidden="true" att-id="${d.data.parentIdRef}"></i></a></div>` : '';
        const navDown = !!d.data.numOfChildren ? `<div class="nav-down" role="link" aria-label="Move one level down" att-id="${d.data.id}"><a><span  att-id="${d.data.id}">${d.data.numOfChildren}</span></a></div>` : '';
        const name = !!d.data.numOfChildren ? `<div class="card-name clickable-name" att-id="${d.data.id}"> ${d.data.displayName} </div>`: `<div class="card-name"> ${d.data.displayName} </div>`;
        return `<div class="row"><div class="col"><div  class="card ${selected}" style="font-family: 'Lato', sans-serif;background-color:${color}; width:${d.width}px;height:${d.height}px;">
        <div class="row h-100">
        <div class="col-1 h-100"><div style="height: 100%;border: 2px solid ${color2};background: ${color2};width: 20px;border-radius: 8px 0px 0px 8px; 
        box-shadow: 0px 0px 0px 1px ${color2};
        "></div></div>
        <div class="col-11 card-body">
                ${navUp}
                ${name}
                <div class="card-det">
                  <div class="card-pos">${d.data.positionTitle}</div>
                  <div class="card-dir">${d.data.directorate.join(' / ')}</div>
                </div>

                <div class="card-contact contact-${d.data.id}">
                  <div class="d-flex"><i class="ph-envelope-simple" aria-hidden="true"></i> <span class="pl-2">${d.data.email}</span></div>
                  <div class="d-flex mt-1"><i class="ph-phone" aria-hidden="true"></i> <span class="pl-2">${d.data.phone}</span></div>
                </div>
                ${navDown}

            </div>
         
        </div>
       </div></div></div>
       `
      })

      .nodeWidth((d:any) =>  d.data.isSelected ? 500 : 350)
      .nodeHeight((d: any) =>  {
          
          const isFFSBrowser = navigator.userAgent.split('Firefox').length > 1 || navigator.userAgent.split('Safari').length > 1;
          let widthRef = 40;
          if(isFFSBrowser) {
            widthRef = 34;
          }
          if(d.data.isSelected) {
            widthRef = 60;
            if(isFFSBrowser) {
              widthRef = 50;
            }
          }         
          const posTitleLength = d.data.positionTitle.length;
          const dirLength = d.data.directorate.join(' / ').length;
          const displayNameLength = d.data.displayName.length;
          const srtLen = posTitleLength + dirLength;
          let diff = [];
          if( displayNameLength >= 27 && isFFSBrowser && !d.data.isSelected) {
            diff.push(30);
            console.log('----------------', d.data.displayName);
            
          } 
          if( posTitleLength > widthRef) {
            diff.push((posTitleLength / widthRef)  * 12);
          } 
          if(dirLength > widthRef) {
            diff.push((dirLength / widthRef) * 12);
          }
          if(d.data.isSelected && srtLen > 60 && diff.length === 0) {
            diff.push(10);
          }   
          const delta =  diff.reduce((partialSum, a) => partialSum + a, 0);     
          console.log(d.data.displayName, isFFSBrowser, posTitleLength, dirLength, delta, navigator.userAgent)     ;
          return (d.data.isSelected ? 135 : 170) + delta * (d.data.isSelected? 1.5 : 1) ;
        }
      )
      .render()
      .expandAll();

      if(window.innerWidth < 768 ) {
        this.chart.fit();
      }

      d3.selectAll('.nav-down,.clickable-name').on('click', (node: any) => {
        const id = node.target.attributes['att-id']?.value;
        this.updateGraphEmitter.emit({ action: 'updateBySearchFromGraph',  value: id });
        
      });

      d3.selectAll('.nav-up').on('click', (node: any) => {
        const id = node.target.attributes['att-id']?.value;
        this.updateGraphEmitter.emit({ action: 'updateBySearchFromGraph',  value: id });
      });      
  }
}