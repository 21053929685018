<div class="input-group p-3 pb-4">
    <!-- <input type="text" class="form-control rounded-0" id="search" aria-describedby="inputGroupPrepend"
        placeholder="Search by staff name" [(ngModel)]="filters.search" (keyup)="update()"> -->
    <!-- <input id="typeahead-basic" type="text" placeholder="Search by staff name" class="form-control rounded-0" [(ngModel)]="model" [ngbTypeahead]="search" /> -->
    <label for="typeahead-basic-{{mode}}" class="visually-hidden">Search by staff name</label>
    <input id="typeahead-basic-{{mode}}" type="text" placeholder="Search by staff name" class="form-control rounded-0"
        [(ngModel)]="model" 
        [ngbTypeahead]="search" 
        (selectItem)="selectedItem($event)"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        />
    <div class="input-group-append">
        <span class="input-group-text" ><i class="ph-magnifying-glass-light" aria-hidden="true"></i></span>
    </div>
</div>

<ng-template #rt let-r="result" let-t="term">
    <div class="matches">
        <div><i class="ph-user-light px-2" aria-hidden="true"></i> <span> <ngb-highlight [result]="r.displayName" [term]="t"></ngb-highlight></span></div>
        <div><i class="ph-tree-structure-light px-2" aria-hidden="true"></i> <small> {{r.directorate}}</small></div>
    </div>
</ng-template>
