import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { getAuthId } from 'src/utils';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.clone({
            setHeaders: {
                Requesto: getAuthId()
            }
        });
        return next.handle(request).pipe(
            tap(() => {},
            (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
               return;
              }
              console.log("unauthorized!, redirecting... to: ", environment.siteUrl);
              window.location.href = `https://my.apa.org/sso/idm/login.seam?individual=true&ERIGHTS_TARGET=${environment.siteUrl}`;
            }})
        );
    }
}