<footer>
    <div class="rwd-footer">
        <div class="back-to-top d-md-none"><a (click)="onClick('header')"><span>▲&nbsp;</span>BACK TO TOP</a></div>
        <div class="standard">
            <div class="container">
                <div class="col-md-12">
                    <ul>
                        <li><a href="https://www.apa.org" target="_parent">APA Home</a></li>
                        <li><a href="https://www.apa.org/support/contact" target="_parent">Contact</a></li>
                        <li><a href="https://www.apa.org/news/press" target="_parent">Press Room</a></li>
                        <li><a href="https://advertising.apa.org" target="_parent">Advertise</a></li>
                        <li><a href="https://psycnet.apa.org" target="_blank">APA PsycNET<sup>®</sup></a></li>
                        <li><a href="https://www.apa.org/about/privacy" target="_parent">Privacy Statement</a></li>
                        <li><a href="https://www.apa.org/about/termsofuse" target="_parent">Terms of Use</a></li>
                        <li><a href="https://www.apa.org/about/accessibility" target="_parent">Accessibility</a></li>
                        <li><a href="https://www.apa.org/website-feedback" target="_parent">Website Feedback</a></li>
                        <li><a href="https://www.apa.org/sitemap" target="_parent">Site Map</a></li>
                        <li><a href="https://www.apa.org/support" target="_parent">Help</a></li>
                    </ul>
                </div>
                <div class="col-md-12">
                    <div>
                        <p><a href="https://www.apa.org/about/contact/copyright" target="_parent">© 2023 American
                                Psychological Association</a></p>
                        <p>750 First St. NE, Washington, DC 20002-4242</p>
                        <p>Telephone: (800) 374-2721; (202) 336-5500</p>
                        <p>TDD/TTY: (202) 336-6123</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>