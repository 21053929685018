<div class="filter-panel">
    <div class="row">
        <div class="col-12">
            <div class="filters d-none d-lg-block">
                <div class="label p-3">Search</div>
                <div>
                    <app-typeahead-basic [list]="suggestedMatches" (selectedItemEmitter)="update($event)" [events]="resetSearchEvent.asObservable()" mode="desktop"></app-typeahead-basic>
                </div>
                <div class="label p-3">Browse by:</div>
                <div>
                    <app-acc-basic [directorates]="directorates" (selectedDirEmitter)="updateDir($event)" [events]="resetDirEvent.asObservable()"></app-acc-basic>
                </div>                
            </div>
            <div class="filters d-block d-lg-none">
                <div class="label p-3">
                    Search & Filter
                    <i class="ph-list" (click)="toggle()" aria-hidden="true"></i>
                </div>
                <div [@collapse]="collapsed">
                    <!-- [ngClass]="{'collapse': !filterExpanded, 'expand': filterExpanded}"> -->
                    <div>
                        <app-typeahead-basic [list]="suggestedMatches" (selectedItemEmitter)="update($event)" [events]="resetSearchEvent.asObservable()" mode="mobile"></app-typeahead-basic>
                    </div>
                    <div class="label p-3">Browse by:</div>
                    <div>
                        <app-acc-basic [directorates]="directorates" (selectedDirEmitter)="updateDir($event)" [events]="resetDirEvent.asObservable()"></app-acc-basic>
                    </div>                
                </div>
            </div>
        </div>
    </div>


</div>