import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class HttpService {

    private url: string;
    private headers: any;
    constructor(private http: HttpClient) {
        this.url = environment.apiUrl;
    }

    get = () => this.http.get(this.url)
}
