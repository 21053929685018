import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from './environments/environment';
import { hasRight } from './utils';

export class AuthGuard implements CanActivate {
    constructor() { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        if (hasRight()) {
            console.log("authorized!");
            return true;
        }
        console.log("unauthorized!, redirecting... to: ", environment.siteUrl);
        window.location.href = `https://my.apa.org/sso/idm/login.seam?individual=true&ERIGHTS_TARGET=${environment.siteUrl}`
        return false;
    }
}